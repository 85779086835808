<!--
 * @Author: qy
 * @LastEditors: qy
 * @description: page description
 * @Date: 2020-07-29 18:39:38
 * @LastEditTime: 2020-07-29 19:41:25
--> 
<template>
  <div class="app-help v" style="height: 100%; overflow: hidden;">
    <div class="padding-10 ta-c h c app-help-header no-flex">
      <div class="padding-10">
        <img src="/static/img/logo.png" style="width: 120px;" />
      </div>
      <div class="padding-10 fc-w">
        <div class="system-name">经销商管理系统</div>
        <div class="en-mini">Distributor Management System</div>
      </div>
      <div class="sep"></div>
      <div class="fc-w fn-name">
        <i class="fa fa-question-circle"></i>&nbsp;帮助中心
      </div>
    </div>
    <div class="flex h">
      <div class="no-flex scroll-able app-help-contents" style="width: 360px;">
        <div>&nbsp;</div>
        <help-content-item v-for="o in contents" :key="o.id" :item="o" :current="current" @select="handleSelect" />
        <div>&nbsp;</div>
      </div>
      <div class="flex">
        <iframe frameborder="0" :src="url" v-if="url" style="width: 100%; height: 100%;" />
      </div>
    </div>
  </div>
</template>

<script>
import helpContentItem from "./content-item";
import request from "@/utils/request";
import config from "@/config";

export default {
  components: {
    helpContentItem,
  },
  data() {
    return {
      current: null,
      contents: [],
      url: null,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      request({
        url: config.hosts.resource + "static/help/contents.json",
        method: "get",
      }).then((res) => {
        this.contents = res;
      });
    },
    handleSelect(_item) {
      if (_item && _item.id) {
        if (this.current && this.current === _item.id) return;
        this.current = _item.id;
        if (/^http(.*)/.test(_item.url)) {
          window.open(_item.url, "_blank", "width=800,height=600");
        } else {
          this.url = "/static/help/" + _item.url;
        }
      }
    },
  },
};
</script>

<style lang="less">
.app-help-header {
  background: linear-gradient(to right, #19467e, #3a6cab);

  .system-name {
    margin-top: 8px;
    font-size: 24px;
    font-weight: bold;
  }

  .en-mini {
    text-transform: uppercase;
    font-size: 12px;
    opacity: 0.3;
    transform: scale(0.75);
  }

  .sep {
    margin: 0 10px;
    height: 48px;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.1);
  }

  .fn-name {
    padding-left: 30px;
    font-size: 32px;
  }
}
.app-help-contents {
  background-color: #f8f8f9;
}
</style>